.Container {
  display: flex;
  padding: 0;
}
.Container.Focus {
  animation: shake 0.25s;
  animation-iteration-count: 1;
}
.Container .Avatar {
  min-width: 24px;
  width: 24px;
  margin-right: 12px;
}
.Container .Avatar .AvatarImg {
  border: 1px solid var(--qt-cool-gray2);
  border-radius: 50%;
  display: flex;
}
.Container .Content {
  padding-right: 0.3rem;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.Container .Content .ContentInner {
  display: flex;
  align-items: flex-end;
  gap: 6px;
}
.Container .Content .Sender {
  color: var(--qt-gray9);
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
}
.Container .Content .NotConfirmCount {
  display: flex;
  align-items: flex-end;
  font-size: 12.5px;
  color: var(--qt-primary6);
}
.Container .Content .Date {
  white-space: nowrap;
  font-size: 11px;
  color: var(--qt-gray7);
}
.Container:hover .MessageOption .Button {
  opacity: 1;
  pointer-events: auto;
}
.Container .MessageOption {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  min-width: 3rem;
  gap: 4px;
}
.Container .MessageOption .Button {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  background-color: var(--qt-cool-gray1);
  border-radius: 18px;
  font-size: 0.7em;
  border: 0;
  min-width: 32px;
  padding: 4px 6px;
}
.Container .MessageOption .Button .Play:not(:disabled) {
  color: var(--qt-primary6);
}
.Container .MessageOption .Button .Stop:not(:disabled) {
  color: var(--qt-red6);
}

@keyframes shake {
  0% {
    transform: translate(0px, 0px);
  }
  33% {
    transform: translate(0px, -5px);
  }
  66% {
    transform: translate(0px, 5px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}