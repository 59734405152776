@keyframes tab-blinking {
  from {
    background-color: inherit;
  }
  to {
    background-color: var(--qt-primary1);
  }
}
.Nav {
  border-bottom: 1px solid var(--qt-divider);
}
.Nav .NavItem {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 56px;
  color: var(--qt-cool-gray6);
  cursor: pointer;
  border-bottom: 3px solid transparent;
  transition: border 0.3s;
  font-weight: 500;
}
.Nav .NavItem:hover {
  background-color: var(--qt-gray3);
}
.Nav .NavItem:active {
  background-color: var(--qt-bg-hover);
}
.Nav .NavItem.On {
  color: var(--qt-primary6);
  border-color: var(--qt-primary6);
}
.Nav .NavItem.Blink {
  animation-duration: 0.5s;
  animation-name: tab-blinking;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}