.ChannelBox {
  position: relative;
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.6px;
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  border-bottom: 0.0625rem solid var(--qt-divider);
  border-left: 3px solid transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}
.ChannelBox:hover {
  background-color: var(--qt-ch-tertiary);
}
.ChannelBox.Active {
  background-color: var(--qt-cool-gray2);
}
.ChannelBox.On {
  border-left: 3px solid var(--qt-primary6);
  animation-name: NewRoomAgentCall;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
@keyframes NewRoomAgentCall {
  0% {
    border-left: 3px solid transparent;
  }
  50% {
    border-left: 3px solid var(--qt-primary6);
  }
  100% {
    border-left: 3px solid transparent;
  }
}
.ChannelBox .ChannelRoom {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}
.ChannelBox .ChannelRoom .RoomDec {
  display: flex;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
}
.ChannelBox .ChannelRoom .RoomCreatedAt {
  color: var(--qt-ch-secondary);
  white-space: nowrap;
  font-size: 10px;
}
.ChannelBox .ChannelId {
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 1rem;
  letter-spacing: -0.2px;
  justify-content: space-between;
}
.ChannelBox .ChannelId .TicketId {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  min-width: 0;
  font-size: 13px;
  color: var(--qt-ch-primary);
}
.ChannelBox .ChannelId .CopyButton {
  border: 0;
  cursor: pointer;
  color: var(--qt-cool-gray6);
}
.ChannelBox .ChannelDec {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 2px;
  gap: 4px;
}
.ChannelBox .ChannelDec .TicketTagList {
  display: flex;
  justify-content: flex-start;
  gap: 3px;
  flex: 1;
  padding: 0;
  margin: 0;
  overflow: hidden;
  max-height: 20px;
  height: 20px;
  flex-wrap: wrap;
}
.ChannelBox .ChannelDec .TicketTagList li {
  font-size: 11px;
  padding: 1px 6px 0;
  display: inline;
  height: 20px;
  align-items: center;
  font-weight: 400;
  border-radius: 10px;
  background-color: var(--qt-primary2);
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--qt-cool-gray7);
}
.ChannelBox .ChannelDec .Message {
  width: 100%;
  color: var(--qt-ch-secondary);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ChannelBox .ChannelDec .NewMessageNum {
  color: #fff;
  padding-inline: 4px;
  padding-bottom: 1.5px;
  background: var(--qt-ch-danger);
  border-radius: 10px;
  min-width: 18px;
  height: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
}

.BackDrop {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
.BackDrop .CloseDialog {
  display: flex;
  background: #fff;
  border-radius: 0.5rem;
  border: 1px solid var(--bs-primary);
  width: 100%;
  height: 100%;
}
.BackDrop .CloseDialog .Title {
  display: flex;
  flex-grow: 3;
  flex-direction: column;
  align-self: center;
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: -0.0125rem;
  padding: 0 0.75rem;
  color: var(--qt-ch-secondary);
}
.BackDrop .CloseDialog .Title .Content {
  font-weight: 500;
  color: var(--qt-ch-primary);
}
.BackDrop .CloseDialog .Button {
  padding: 0.75rem;
  align-self: center;
}
.BackDrop .CloseDialog .Button button {
  padding: 0;
  color: var(--qt-cool-gray6);
  border-radius: 100%;
  border-color: white;
  background-color: white;
}
.BackDrop .CloseDialog .Button button:hover {
  background-color: var(--qt-cool-gray2);
  border-color: var(--qt-cool-gray2);
}
.BackDrop .CloseDialog .Button button:active {
  background-color: var(--qt-cool-gray3);
  border-color: var(--qt-cool-gray3);
}